<template>
  <div id="login">
    <PasswordReset
      v-if="showPasswordReset"
      @close="togglePasswordReset()"
    ></PasswordReset>
    <section>
      <div class="col1">
        <h1>Go Hang</h1>
        <h3>
          Just wondering if you wanna hang tbh 🤔
        </h3>
      </div>
      <div :class="{ 'signup-form': !showLoginForm }" class="col2">
        <form v-if="showLoginForm" @submit.prevent>
          <h1>Welcome Back</h1>
          <div>
            <label for="email1">Email</label>
            <input
              v-model.trim="loginForm.email"
              type="text"
              placeholder="you@email.com"
              id="email1"
            />
          </div>
          <div>
            <label for="password1">Password</label>
            <input
              v-model.trim="loginForm.password"
              type="password"
              placeholder="******"
              id="password1"
            />
          </div>
          <button @click="login()" class="button">Log In</button>
          <div class="extras">
            <a @click="togglePasswordReset()">Forgot Password</a>
            <a @click="toggleForm()">Create an Account</a>
          </div>
        </form>
        <form v-else @submit.prevent>
          <h1>Get Started</h1>
          <div>
            <label for="userName">Profile Name (public)</label>
            <input
              v-model.trim="signupForm.userName"
              type="text"
              placeholder="Short user name"
              id="userName"
            />
          </div>
          <div>
            <label for="firstName">First Name</label>
            <input
              v-model.trim="signupForm.firstName"
              type="text"
              placeholder="Your First Name"
              id="firstName"
            />
          </div>
          <div>
            <label for="phone">Mobile</label>
            <input
              v-model.trim="signupForm.phone"
              type="phone"
              placeholder="(212) 123-4567"
              id="phone"
            />
          </div>
          <div>
            <label for="email2">Email</label>
            <input
              v-model.trim="signupForm.email"
              type="text"
              placeholder="you@email.com"
              id="email2"
            />
          </div>
          <div>
            <label for="password2">Password</label>
            <input
              v-model.trim="signupForm.password"
              type="password"
              placeholder="min 6 characters"
              id="password2"
            />
          </div>
          <button @click="signup()" class="button">Sign Up</button>
          <div class="extras">
            <a @click="toggleForm()">Back to Log In</a>
          </div>
          <ErrorDisplay/>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import PasswordReset from '@/components/PasswordReset.vue';
import ErrorDisplay from '@/components/ErrorDisplay.vue';

export default {
  components: {
    ErrorDisplay,
    PasswordReset,
  },
  data() {
    return {
      error: Error,
      showPasswordReset: false,
      showLoginForm: true,
      loginForm: {
        email: '',
        password: '',
      },
      signupForm: {
        userName: '',
        firstName: '',
        email: '',
        phone: '',
        password: '',
      },
    };
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        email: this.loginForm.email,
        password: this.loginForm.password,
      });
    },
    signup() {
      this.$store.dispatch('signup', {
        email: this.signupForm.email,
        password: this.signupForm.password,
        userName: this.signupForm.userName,
        firstName: this.signupForm.firstName,
      });
    },
    toggleForm() {
      this.showLoginForm = !this.showLoginForm;
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset;
    },
  },
};
</script>
