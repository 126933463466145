  <template>
  <div id="error">
      <p v-if="error" class="error">{{ error ? error.message : '' }}</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['error']),
  },
};
</script>
